import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/img/Tabtila Logo Design 2.png";
import { Link } from 'react-router-dom';
import heart from "../../assets/svg/heart.svg";
import cart from "../../assets/svg/cart.svg";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // Initially set modalOpen to false
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        // Set a 7-second delay before showing the modal
        const timer = setTimeout(() => {
            setModalOpen(true);
        }, 7000); // 7000 milliseconds = 7 seconds

        return () => clearTimeout(timer); // Cleanup to reset the timer on component unmount
    }, []);

    useEffect(() => {
        if (modalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalOpen]);

    const handleSignUpClick = () => {
        navigate('/signup'); // Navigate to /signup
    };

    return (
        <>
            <div className="navbar">
                <div className="navbar-left">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                </div>
                <div className={`navbar-center ${isMenuOpen ? 'active' : ''}`}>
                    <button className="close-menu-icon" onClick={toggleMenu}>
                        <i className="fas fa-times"></i>
                    </button>
                    <Link to="/" className="navbar-link active">Home</Link>
                    <Link to="/" className="navbar-link">
                        All Gifts <i className="fas fa-chevron-down"></i> {/* Dropdown arrow */}
                    </Link>
                    <Link to="/" className="navbar-link">Personalized</Link>
                    <Link to="/" className="navbar-link">Combos</Link>
                </div>
                <div className="navbar-right">
                    <div className='mx-3'>
                        <Link to="/" className="navbar-icon">
                            <i className="fas fa-search"></i>
                        </Link>
                        <Link to="/" className="navbar-icon">
                            <img src={heart} alt='heart' />
                        </Link>
                        <Link to="/" className="navbar-icon">
                            <img src={cart} alt='cart' />
                        </Link>
                    </div>
                    <Link to="/login">
                        <button className="navbar-signin">
                            SIGN IN
                        </button>
                    </Link>
                    {!isMenuOpen && (
                        <button className="navbar-menu-icon" onClick={toggleMenu}>
                            <i className="fas fa-bars"></i>
                        </button>
                    )}
                </div>
                <div className={`overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
            </div>

            {/* Signup Modal */}
            <div
                className={`modal fade ${modalOpen ? "show" : ""}`}
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden={!modalOpen}
                style={{
                    display: modalOpen ? "block" : "none",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    overflow: "hidden"
                }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content py-1">
                        <div className="modal-bg-img" style={{ position: "relative" }}>
                            {/* Close Icon */}
                            <button 
                                className="modal-close-btn" 
                                onClick={toggleModal}
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "2.2rem",
                                    color: "#fff",
                                    cursor: "pointer"
                                }}
                            >
                                &times;
                            </button>

                            <div className="modal-main-body">
                                <img src={logo} alt="logo" style={{ width: "80px" }} />
                                <p className="title-modal">Just For You</p>
                                <p className="discount-text">50% OFF</p>
                                <p className="subtext mb-4">
                                    <span style={{ color: "#9E3040" }}>Be amon</span><span style={{ color: "#F26C7E" }}>g the first 5000 to enjoy a</span><br />
                                    <span style={{ color: "#9E3040" }}>flat 50</span><span style={{ color: "#F26C7E" }}>% off. Pre-sign up now!</span>
                                </p>
                                <button 
                                    className="modal-btn" 
                                    style={{ background: "#F26C7E" }}
                                    onClick={handleSignUpClick}
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
