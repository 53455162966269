import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team1 from "../../assets/img/team (1).png";
import team2 from "../../assets/img/team (2).png";
import { FaLinkedin } from "react-icons/fa6";

function TeamCard() {
    const teamMembers = [
        {
            name: "Nare Avagyan",
            title: "Director",
            image: team1,
            linkedin: "https://www.linkedin.com/in/nareavagyan/"
        },
        {
            name: "Arman Aramyan",
            title: "CEO",
            image: team2,
            linkedin: "https://www.linkedin.com/in/armanaramyan/"
        },
        {
            name: "Arman Aramyan",
            title: "COO",
            image: team2,
            linkedin: "https://www.linkedin.com/in/armanaramyan/"
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='container py-5 px-5'>
                <div className='team-container text-center pb-5 pt-3'>
                    <h2>Our team</h2>
                    <p style={{ fontSize: "20px" }}>Our team is a dynamic group of passionate technologists and problem-solvers <br />
                        dedicated to revolutionizing the industry. With a combined wealth of experience <br />
                        and a shared vision for the future, we're committed to creating innovative <br />
                        solutions that empower our customers.
                    </p>
                </div>

                <Slider {...settings}>
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-card" style={{ height: "100%" }}>
                            <div className="card text-center py-5">
                                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin size={25} color='white'
                                        style={{
                                            position: 'absolute', top: '10px', right: '10px',
                                            width: '24px', height: '24px'
                                        }} />
                                </a>
                                <img src={member.image} alt={member.name} className="img-fluid" style={{ borderRadius: '10px' }} />
                                <div className="card-body">
                                    <h5 className="card-title">{member.title}</h5>
                                    <p className="card-text">{member.name}</p>

                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
}

export default TeamCard;