import React from 'react';
import aboutLine from "../../../assets/svg/Liner.svg"

function Section1() {
    return (
        <div className='bg-aboutus'>
            <div class="card mb-3 aboutus-card py-4 px-5">
                <div class="card-body">
                    <p className="about-heading">
                        <img src={aboutLine} alt='line' /> &nbsp;ABOUT US</p>
                    <p className="about-paragraph">
                        We're passionate about making gifting a joyful and stress-free experience. Let us Help<br />
                        You celebrate life's special moments in style.
                    </p>
                    <div className="button-container">
                        <button className="button buy-btn">Buy Products</button>
                        <button className="button buy-btn"
                            style={{ background: "transparent", border: "2px solid white", color: "white" }}
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>


            {/* <div className="overlay"></div>
      <div className="about-content">
        <h2 className="heading">ABOUT US</h2>
        <p className="paragraph">
          The Meaning Of Production In Carlio Is The Creation, Development, And The Path To Progress, And The Starting Point To Achieve The Goals That We All Have. The Petroforce Brand, With Over 20 Years Of Experience In The Oil And Petrochemical Industry.
        </p>
        <div className="button-container">
          <button className="button">Buy Products</button>
          <button className="button-secondary">Contact Us</button>
        </div>
      </div> */}
        </div>
    );
}

export default Section1;