import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CenteredLoader from '../components/CenteredLoader'; // Adjust the path if necessary
import AboutMain from "../views/AboutPage/AboutMain";
import TeamMain from "../views/OurTeam/TeamMain";
import ContactMain from "../views/ContactUs/ContactMain";


// Lazy load the Main component
const Main = lazy(() => import("../components/Main"));
const PrivacyPolicyContent = lazy(() => import("../components/PrivacyPage/PrivacyPolicyContent"));
const Login = lazy(() => import("../components/auth/LoginPage"));
const SignUp = lazy(() => import("../components/SignUp/SignUpForm"));

function RouteStack() {
    return (
        <Router>
            <Suspense fallback={<CenteredLoader />}>
                <Routes>
                    <Route exact path="/" element={<Main />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicyContent />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/signup" element={<SignUp />} />
                    <Route exact path="/about" element={<AboutMain />} />
                    <Route exact path="/our-team" element={<TeamMain />} />
                    <Route exact path="/contact-us" element={<ContactMain />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default RouteStack;
