import React from 'react'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/Footer/Footer'
import ContactCard from './ContactCard'

function ContactMain() {
    return (
        <>
            <Navbar />
            <ContactCard />
            <Footer />
        </>
    )
}

export default ContactMain