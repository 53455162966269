import React from 'react';
import contactImg from "../../assets/img/Contact us-rafiki 1.png";

function ContactCard() {
    return (
        <div className=''>
            <div className="contact-page pb-5">
                <div className='contact-head container-fluid pt-5 mt-5'>
                    <p style={{ fontSize: "40px", fontWeight: "600" }}>Send In Your Thoughts</p>
                    <p style={{ color: "white" }}>Your feedback matters. Share your thoughts with us.</p>
                </div>
                <div className='contact-main px-5'>
                    <div className="contact-form">
                        <form>
                            <div className="contact-form-group">
                                <div className='top-input'>
                                    <label>First Name</label><br />
                                    <input type="text" required className='contact-input' 
                                      />
                                </div>
                                <div className='top-input'>
                                    <label>Last Name</label><br />
                                    <input type="text" required className='contact-input'
                                        />
                                </div>
                            </div>
                            <label className='contact-label'>Email</label>
                            <input type="email" required className='contact-input' />
                            <label className='contact-label'>Phone</label>
                            <input type="tel" required className='contact-input' />
                            <label className='contact-label'>Message</label>
                            <textarea required className='contact-textarea'></textarea>
                            <div className='contact-btn'>
                                <button type="submit">Send</button>

                            </div>
                        </form>
                    </div>
                    <div className="contact-image">
                        <img src={contactImg} alt="Contact illustration" />
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ContactCard;