import React from 'react';
import aboutImg from "../../../assets/svg/Photo.svg";
import aboutIcon from "../../../assets/svg/Icon.svg";
import aboutDiv from "../../../assets/svg/Company.svg";

function Section2() {
    return (
        <div className='container-fluid section2-container'>
            <div className="card section2-card">
                <div className="row section2-row">
                <div className="col-md-6 col-sm-12 section2-image-column">
                        <img src={aboutImg} className="img-fluid section2-main-image" alt="About us" />
                    </div>
                    <div className="col-md-6 col-sm-12 section2-text-column">
                        <div className="card-body section2-card-body">
                            <p className="section2-title">
                                <img src={aboutIcon} alt='About us icon' /> About us
                            </p>
                            <p className="section2-heading">Our Company Overview</p>
                            <p className="section2-description">
                                We understand the importance of celebrating life's special occasions. But between busy schedules and overflowing to-do lists, remembering every birthday, anniversary, or milestone can be a challenge.
                            </p>
                            <img src={aboutDiv} alt='Company overview image' 
                            className='section2-overview-image' />
                            <hr className='section2-divider' />
                            <p className='section2-details'>
                                We're an AI-powered gifting platform that takes the stress out of gift-giving. We combine the ease of online shopping with the thoughtfulness of a personalized touch, ensuring your gifts always arrive on time and leave a lasting impression.
                            </p>
                            <button className='section2-team-button about-team'>Meet Our Team</button>
                        </div>
                    </div>
               
                </div>
            </div>
        </div>
    );
}

export default Section2;