import React from 'react';
import { CircleLoader } from 'react-spinners';

function CenteredLoader() {
  return (
    <div style={styles.loaderContainer}>
      <CircleLoader
        color="#9E3040"
        loading
        size={200}
        speedMultiplier={0.5}
      />
    </div>
  );
}

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height of the viewport
    width: '100vw',  // Full width of the viewport
    position: 'fixed', // Make sure it stays centered even if the page scrolls
    top: 0,
    left: 0,
    backgroundColor: '#f7f7f7', // Optional: Light background color to enhance visibility
    zIndex: 9999, // Ensure it stays on top of all other elements
  },
};

export default CenteredLoader;
