import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/img/Tabtila Logo Design 2.png";
import './footer.css';
import { useNavigate } from 'react-router-dom';


function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer-container mt-5">
      <div className="logo-section">
        <img src={logo} alt="Tabtila Logo" className="logo" />
        <div className="powered-by-section">
          <p className="powered-by-text">Powered by <strong>MANTIQI</strong></p>
        </div>
      </div>
      <div className="link-sections">
        <div className="link-section">
          <h4 className="section-title">About</h4>
          <ul className="link-list">
          <li
              className="link-item"
              onClick={() => {
                navigate("/about");
              }}
            >
              About Us
            </li>
            <li className="link-item">Our Mission</li>
            <li className="link-item"
              onClick={() => {
                navigate("/our-team");
              }}
            >
              Our Team
            </li>
            <li className="link-item">Events</li>
          </ul>
        </div>
        <div className="link-section">
          <h4 className="section-title">Contact Us</h4>
          <ul className="link-list">
            <li className="link-item">Mail Us</li>
            <li className="link-item"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Chat With Us
            </li>
            <li className="link-item">Get On A Call</li>
          </ul>
        </div>
        <div className="link-section">
          <h4 className="section-title">Conditions</h4>
          <ul className="link-list">
            <li className="link-item">
              <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            </li>
            <li className="link-item">Terms Of Use</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
