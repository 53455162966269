import React from 'react';
import icon1 from "../../../assets/svg/about-icons (1).svg";
import icon2 from "../../../assets/svg/about-icons (2).svg";
import icon3 from "../../../assets/svg/about-icons (3).svg";

function Section3() {
    return (
        <div className='section3-background'>
            <div className='container'>
                <div className="row section3-row">
                    <div className="col section3-col">
                        <div className="card section3-card">
                            <img src={icon1} className="card-img-top section3-icon" alt="Centralized Event Calendar" />
                            <div className="card-body">
                                <h5 className="card-title section3-title">Centralized <br/>Event Calendar</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col section3-col">
                        <div className="card section3-card">
                            <img src={icon2} className="card-img-top section3-icon" alt="AI-Powered Greet Cards" />
                            <div className="card-body">
                                <h5 className="card-title section3-title">AI-Powered <br/>Greet Cards</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col section3-col">
                        <div className="card section3-card">
                            <img src={icon3} className="card-img-top section3-icon" alt="Delivered When It Counts" />
                            <div className="card-body">
                                <h5 className="card-title section3-title">Delivered When It <br/>Counts</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section3;