import React, { useState } from 'react';
import aboutImg from "../../../assets/svg/Object - Photo.svg";
import aboutIcon from "../../../assets/svg/about-qs.svg";

function Section4() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className='container-fluid section2-container'>
            <div className="card section2-card">
                <div className="row section2-row">
                    <div className="col-md-6 col-sm-12 section2-image-column">
                        <img src={aboutImg} className="img-fluid section2-main-image" alt="About us" />
                    </div>
                    <div className="col-md-6 col-sm-12 section2-text-column">
                        <div className="card-body section2-card-body">
                            <p className="section2-title" style={{ fontSize: "40px", fontWeight: "600" }}>
                                <img src={aboutIcon} alt='About us icon' /> <br />Our Mission:
                            </p>
                            {/* FAQ Section */}
                            <div className="faq-section mt-4">
                                <div className="accordion" id="faqAccordion">
                                    {/* First FAQ Item */}
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button 
                                                    className="btn btn-link faq-btn" 
                                                    type="button" 
                                                    onClick={() => toggleAccordion(0)}
                                                    aria-expanded={activeIndex === 0} 
                                                    aria-controls="collapseOne"
                                                    style={{ 
                                                        fontWeight: activeIndex === 0 ? '700' : '400',
                                                        color: activeIndex === 0 ? '#b11251' : '#333'
                                                    }}
                                                >
                                                    Simplify your gifting experience
                                                    <span className="faq-icon">
                                                        {activeIndex === 0 ? '-' : '+'}
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className={`collapse ${activeIndex === 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#faqAccordion">
                                            <div className="card-body">
                                                Effortlessly manage all your gifting needs - from birthdays and holidays to work anniversaries and "just because" moments - in one centralized location.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* Second FAQ Item */}
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button 
                                                    className="btn btn-link faq-btn" 
                                                    type="button" 
                                                    onClick={() => toggleAccordion(1)}
                                                    aria-expanded={activeIndex === 1} 
                                                    aria-controls="collapseTwo"
                                                    style={{ 
                                                        fontWeight: activeIndex === 1 ? '700' : '400',
                                                        color: activeIndex === 1 ? '#b11251' : '#333'
                                                    }}
                                                >
                                                    Deliver gifts that matter
                                                    <span className="faq-icon">
                                                        {activeIndex === 1 ? '-' : '+'}
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className={`collapse ${activeIndex === 1 ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#faqAccordion">
                                            <div className="card-body">
                                                Here you can add more detailed information about delivering gifts that matter.
                                            </div>
                                        </div>
                                    </div>

                                    {/* Third FAQ Item */}
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button 
                                                    className="btn btn-link faq-btn" 
                                                    type="button" 
                                                    onClick={() => toggleAccordion(2)}
                                                    aria-expanded={activeIndex === 2} 
                                                    aria-controls="collapseThree"
                                                    style={{ 
                                                        fontWeight: activeIndex === 2 ? '700' : '400',
                                                        color: activeIndex === 2 ? '#b11251' : '#333'
                                                    }}
                                                >
                                                    Guarantee on-time delivery
                                                    <span className="faq-icon">
                                                        {activeIndex === 2 ? '-' : '+'}
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className={`collapse ${activeIndex === 2 ? 'show' : ''}`} aria-labelledby="headingThree" data-parent="#faqAccordion">
                                            <div className="card-body">
                                                Here you can add more detailed information about guaranteeing on-time delivery.
                                            </div>
                                        </div>
                                    </div>

                                    {/* Fourth FAQ Item */}
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button 
                                                    className="btn btn-link faq-btn" 
                                                    type="button" 
                                                    onClick={() => toggleAccordion(3)}
                                                    aria-expanded={activeIndex === 3} 
                                                    aria-controls="collapseFour"
                                                    style={{ 
                                                        fontWeight: activeIndex === 3 ? '700' : '400',
                                                        color: activeIndex === 3 ? '#b11251' : '#333'
                                                    }}
                                                >
                                                    Empower businesses to celebrate employees
                                                    <span className="faq-icon">
                                                        {activeIndex === 3 ? '-' : '+'}
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className={`collapse ${activeIndex === 3 ? 'show' : ''}`} aria-labelledby="headingFour" data-parent="#faqAccordion">
                                            <div className="card-body">
                                                Here you can add more detailed information about empowering businesses to celebrate employees.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Section4;