import React from "react";
import RouteStack from './MainStack/RouteStack';

function App() {
  return (
    <>
      <RouteStack />
    </>
  );
}

export default App;
